<template>
    <div ref="prev" style="width: 100%; height: 100%">
        <b-row ref="bgprev" id="bg-prev" :style="myStyles">
            <div>
                <div v-show="editDbMode">
                    <b-icon class="dragIcon" icon="arrows-move" font-scale="2" style="position: absolute; top: 5px; left: 5px" />
                    <p>{{opt.title}}</p>
                    <b-button variant="secondary" size="sm" @click="modalEdit = !modalEdit">Edit gauge</b-button>
                </div>
                <div>
                    <font-awesome-icon v-show="!editDbMode" class="ml-1" icon="table" size="lg" @click="showStatistics = !showStatistics" :style="statIconStyle" />
                    <highcharts v-show="!editDbMode" class="gauge" :options="chartOptions" :updateArgs="updateArgs" :deepCopyOnUpdate="true"></highcharts>
                </div>
            </div>
            <b-modal title="Edit component" v-model="modalEdit" @ok="handleOk" @cancel="handleCancel">
                <b-form-group>
                    <b-row>
                        <b-col cols="6">
                            <strong>Storages to show</strong>
                            <b-form-select class="mt-1" v-model="storages" :options="storageOptions"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-row>
                                <strong>Chart options</strong>
                            </b-row>
                            <b-row>
                                <b-col class="d-flex flex-row" cols="4">
                                    <label for="scaleFrom">Scale from:</label>
                                    <b-form-input size="sm" id="scaleFrom" type="number" v-model="opt.scaleFrom"></b-form-input>
                                </b-col>
                                <b-col class="d-flex flex-row" cols="4">
                                    <label for="scaleTo">to:</label>
                                    <b-form-input size="sm" id="scaleTo" type="number" v-model="opt.scaleTo"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <strong>Color stops:</strong>
                            </b-row>
                            <b-row class="mt-1" v-for="(stop, index) in opt.stops" :key="stop.id">
                                <b-col class="d-flex flex-row" cols="4">
                                    <label for="stop">Stop:</label>
                                    <b-form-input size="sm" id="stop" type="number" v-model="opt.stops[index].stop"></b-form-input>
                                </b-col>
                                <b-col cols="2">
                                    <b-form-input size="sm" type="color" v-model="opt.stops[index].color"></b-form-input>
                                </b-col>
                                <b-col cols="2">
                                    <b-button variant="secondary" size="sm" @click="removeStop(index)">X</b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-button variant="secondary" size="sm" @click="addStop">Add</b-button>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col class="d-flex flex-row" cols="10">
                                    <label for="title">Title:</label>
                                    <b-form-input class="ml-1" size="sm" id="title" type="text" v-model="opt.title"></b-form-input>
                                </b-col>
                                <b-col cols="2">
                                    <b-form-input size="sm" id="titleColor" type="color" v-model="opt.titleColor"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <strong>Configuration templates</strong>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label for="newTemplateName">New template:</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-input size="sm" id="newTemplateName" type="text" v-model="newTemplateName"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="secondary" size="sm" @click="templateSave">Add</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label for="selectTemplate">Select template:</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-select size="sm" id="selectTemplate" type="text" :options="confTemplates" @change="templateChange" v-model="selectedTemplate" :select-size="1"></b-form-select>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="secondary" size="sm" @click="updateTemplate">Update</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="remove"
                                     name="checkbox-remove"
                                     value="yes"
                                     unchecked-value="no">
                        Remove this component
                    </b-form-checkbox>
                </b-form-group>
            </b-modal>
            <statmodal :showStatistics.sync="showStatistics" :statistics="statistics" :opt="opt"></statmodal>
        </b-row>
    </div>
</template>

<script>
    import { Chart } from 'highcharts-vue'
    import Highcharts from 'highcharts';
    import more from 'highcharts/highcharts-more';
    import solidGauge from 'highcharts/modules/solid-gauge';
    import boost from "highcharts/modules/boost";
    more(Highcharts);
    solidGauge(Highcharts);
    boost(Highcharts);
    import { bus } from '@/main.js';
    import { hcGauge2Tmpl } from '@/assets/l4a_jsAssets.js'
    import { uuid } from 'vue-uuid';
    import StatModal from '@/components/Dashboard/statModal.vue'
    export default {
        name: 'gauge',
        props: ['dbid', 'itemid', 'comp'],
        data() {
            return {
                x: 0,
                y: 0,
                modalEdit: false,
                showStatistics: false,
                selected: '',
                remove: 'no',
                opt: {
                    stops: []
                },
                selectedStorages: [],
                seriesConf: [],
                yaxisOptions: ['y1', 'y2'],
                y1Unit: 'tbd',
                y2Unit: 'tbd',
                subTitle: 'tbd',
                statistics: {
                    day: {
                        minValue: 0,
                        minTime: '',
                        maxValue: 0,
                        maxTime: '',
                        avgValue: 0
                    },
                    month: {
                        minValue: 0,
                        minTime: '',
                        maxValue: 0,
                        maxTime: '',
                        avgValue: 0
                    },
                    year: {
                        minValue: 0,
                        minTime: '',
                        maxValue: 0,
                        maxTime: '',
                        avgValue: 0
                    }
                },
                series: [{
                    name: 'Waiting...',
                    data: [0],
                    dataLabels: {
                        overflow: 'allow',
                        padding: 0,
                        allowOverlap: true,
                        borderWidth: 0
                    }
                }],
                updateArgs: [true, true],
                newTemplateName: '',
                selectedTemplate: '-'
            }
        },
        computed: {
            myStyles() {
                return {
                    'width': '100%',
                    'height': '100%',
                    'background-color': (this.editDbMode) ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0)',
                    'border-style': (this.editDbMode) ? 'solid' : 'none',
                    'border-width': '1px',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'center',
                    'margin-left': '0px'
                }
            },
            mySize() {
                return {
                    width: this.x,
                    height: this.y
                }
            },
            statIconStyle() {
                return {
                    'cursor': 'pointer',
                    'position': 'absolute',
                    'z-index': 100,
                    'color': this.opt.titleColor
                }

            },
            componentOptions() {
                let c = [];
                let availableComponents = this.$store.getters.dbComponents
                for (let component of availableComponents) {
                    c.push({ text: component.name, value: component.name })
                }
                return c
            },
            storageOptions() {
                let options = []
                for (let storage of this.$store.getters.storages) {
                    options.push({ value: storage.placeId, text: storage.placeName })
                }
                return options
            },
            confTemplates() {
                let options = []
                for (let template of this.$store.getters.confTemplates) {
                    if (template.type == this.comp.name) options.push({ value: template.name, text: template.name })
                }
                return options
            },
            storages: {
                get: function () {
                    if (this.selectedStorages.length == 0 && typeof this.comp.config.storages !== 'undefined') {
                        return this.comp.config.storages[0]
                    } else {
                        return this.selectedStorages[0]
                    }
                },
                set: function (newStorages) {
                    // Update seriesConf
                    this.seriesConf = [{ storageId: newStorages, axis: 'y1', color: '#000000' }]
                    this.selectedStorages = [newStorages]

                }
            },
            editDbMode() {
                return this.$store.getters.editDbMode;
            },
            chartOptions() {
                let options = JSON.parse(JSON.stringify(hcGauge2Tmpl))
                options.chart.width = this.x
                options.chart.height = this.y
                options.title.style.color = this.opt.titleColor
                options.title.style.fontSize = this.y * 0.1
                options.title.y = this.y * 0.04
                options.title.text = this.opt.title
                options.yAxis.title = {
                    text: this.subTitle,
                    style: {
                        color: this.opt.titleColor,
                        fontSize: this.y * 0.07
                    },
                    y: -this.y * 0.28
                }
                options.plotOptions.solidgauge.dataLabels.y = -(this.y * 0.2 - 5)
                options.yAxis.min = isNaN(parseFloat(this.opt.scaleFrom)) ? 0 : parseFloat(this.opt.scaleFrom)
                options.yAxis.labels.style.color = this.opt.titleColor
                options.yAxis.max = isNaN(parseFloat(this.opt.scaleTo)) ? 10 : parseFloat(this.opt.scaleTo)
                options.series.splice(0, options.series.length, ...this.series)
                if (this.opt.stops.length > 0) {
                    options.yAxis.stops = []
                    for (let stop of this.opt.stops) {
                        options.yAxis.stops.push([stop.stop, stop.color])
                    }
                }
                return options
            }

        },
        created() {
            if (typeof this.comp.config.series !== 'undefined') {
                this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
            }
            if (typeof this.comp.config.storages !== 'undefined') {
                this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
            }
            if (typeof this.comp.config.opt !== 'undefined') {
                this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
            } else {
                this.opt.titleColor = hcGauge2Tmpl.title.style.color
                this.opt.title = hcGauge2Tmpl.title.text
            }
            bus.$on('newData', () => {
                if (this.$store.getters.data.length > 0 && typeof this.comp.config.storages !== 'undefined' && this.comp.config.storages.length > 0) {
                    let series = []
                    let y1Unit = ''
                    let subTitle = ''
                    //let statistics = {}
                    let dataLabelSize = this.y * 0.09
                    for (let storage of this.comp.config.storages) {
                        if (this.$store.getters.data.findIndex(x => x.placeId === storage) !== -1) {
                            let metricId = this.$store.getters.storages.find(x => x.placeId === storage).metricId
                            y1Unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                            series.push({
                                name: this.$store.getters.storages.find(x => x.placeId === storage).placeName,
                                data: [Math.round(this.$store.getters.data.find(x => x.placeId === storage).valueNow * 10) / 10],
                                tooltip: {
                                    valueSuffix: this.$store.getters.metrics.find(x => x.id === metricId).unit,
                                },
                                dataLabels: {
                                    format:
                                        '<div style="text-align:center">' +
                                        '<span style="font-size:' + dataLabelSize + 'px; color: ' + this.opt.titleColor + '">{y}</span><br/>' +
                                        '<span style="font-size:' + (dataLabelSize * 0.7
                                        ) + 'px; color: ' + this.opt.titleColor + '">' + y1Unit + '</span>' +
                                        '</div>'
                                }/*,
                                dataLabels: {
                                    overflow: 'allow',
                                    padding: 0,
                                    allowOverlap: true,
                                    borderWidth: 0
                                }*/
                            })
                            subTitle = new Date(this.$store.getters.data.find(x => x.placeId === storage).timeNow).toLocaleString()
                            //statistics.day = this.$store.getters.data.find(x => x.placeId === storage).statDay
                            //statistics.month = this.$store.getters.data.find(x => x.placeId === storage).statMonth
                            //statistics.year = this.$store.getters.data.find(x => x.placeId === storage).statYear
                            //statistics.unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                        }
                    }
                    this.y1Unit = y1Unit
                    this.subTitle = subTitle
                    this.series = series
                    //this.statistics = statistics
                }
            });
            bus.$on('newStatistics', () => {
                if (this.$store.getters.statistics.length > 0 && typeof this.comp.config.storages !== 'undefined' && this.comp.config.storages.length > 0) {
                    let statistics = {}
                    for (let storage of this.comp.config.storages) {
                        if (this.$store.getters.statistics.findIndex(x => x.placeId === storage) !== -1) {
                            let metricId = this.$store.getters.storages.find(x => x.placeId === storage).metricId
                            statistics.day = this.$store.getters.statistics.find(x => x.placeId === storage).statDay
                            statistics.month = this.$store.getters.statistics.find(x => x.placeId === storage).statMonth
                            statistics.year = this.$store.getters.statistics.find(x => x.placeId === storage).statYear
                            statistics.unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                        }
                    }
                    this.statistics = statistics
                }
            });
            bus.$on('itemResized', (newSize) => {
                if (newSize.i == this.itemid) {
                    this.x = newSize.wpx
                    this.y = newSize.hpx
                }
            })
        },
        mounted() {
            this.x = this.$refs.prev.clientWidth;
            this.y = this.$refs.prev.clientHeight;
            this.$nextTick(() => {
                window.addEventListener('resize', () => {
                    this.$nextTick(() => {
                        if (typeof this.$refs.prev !== 'undefined') {
                            this.x = this.$refs.prev.clientWidth;
                            this.y = this.$refs.prev.clientHeight;
                        }
                    });
                });
            })
        },
        methods: {
            handleOk() {
                let payload = {
                    dbid: this.dbid,
                    itemid: this.itemid,
                    newConfig: {
                        storages: this.selectedStorages,
                        /*chartOptions: this.chartOptions,*/
                        opt: this.opt,
                        series: this.seriesConf
                    }
                }
                this.$store.commit('updateCompConfig', payload);
                // add additional component
                if (this.selected != '') {
                    let compIndex = this.$store.getters.dbComponents.findIndex(x => x.name === this.selected);
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid,
                        newComp: this.$store.getters.dbComponents[compIndex]
                    }
                    this.selected = '';
                    this.$store.commit('addComp', payload);
                } else if (this.remove == 'yes') {
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid
                    }
                    this.remove = 'no'
                    this.$emit('removed', payload)
                }
                this.$store.commit('updateStorageIds', this.dbid)
            },
            handleCancel() {
                if (typeof this.comp.config.series !== 'undefined') {
                    this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
                }
                if (typeof this.comp.config.storages !== 'undefined') {
                    this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
                }
                if (typeof this.comp.config.opt !== 'undefined') {
                    this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                }
            },
            templateChange() {
                let tmpl = JSON.parse(JSON.stringify(this.$store.getters.confTemplates.find(x => x.name === this.selectedTemplate).tmpl))
                tmpl.opt.title = this.opt.title
                this.opt = tmpl.opt
                //this.plotBands = tmpl.plotBands
            },
            templateSave() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.newTemplateName,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                this.$store.commit('addConfTemplate', payload)
                this.selectedTemplate = this.newTemplateName
                this.newTemplateName = ''
            },
            updateTemplate() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.selectedTemplate,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                this.$store.commit('updateConfTemplate', payload)
            },
            addStop() {
                this.opt.stops.push({ id: uuid.v4(), stop: 0, color: '#000000' })
            },
            removeStop(index) {
                this.opt.stops.splice(index, 1)
            }
        },
        components: {
            highcharts: Chart,
            statmodal: StatModal
        }
    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>